import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { useWizardListener } from '@mwkt/react-magic-frame';

import { LOCALE_ALIASES, NEXT_INTL_LOCALE_CODE_MAP } from 'config/locales';
import { detectLocale } from 'utils/intl';
import { updateLocale } from 'state/user/user-actions';
import { COOKIES_SETTINGS, SKIP_ACCESS_TOKEN_GUARD_COOKIE } from '../constants/cookies.constants';
import { FrameWizardAction } from '../constants/frame-wizard.constants';

const useLocaleSync = () => {
  const dispatch = useDispatch();

  useWizardListener(FrameWizardAction.SyncLocale, (data) => {
    const locale = NEXT_INTL_LOCALE_CODE_MAP[data.locale] ?? LOCALE_ALIASES.en;

    if (detectLocale() !== locale) {
      dispatch(updateLocale(locale));

      // To skip access token guard after full app reload caused by IntlProvider
      Cookies.set(SKIP_ACCESS_TOKEN_GUARD_COOKIE, 'true', COOKIES_SETTINGS);
    }
  });
};

export default useLocaleSync;
