import { FeatureFlagsState } from 'state/feature-flags/feature-flags-initial-state';

/**
 * List of all feature flag's keys that will be used in the application
 *
 * @example
 * export type FeatureFlagKeys = 'oldSuspendedUserFlow' | 'enableOldSuspendedUserFlow';
 */
export type FeatureFlagKeys =
  | 'emergencyMessage'
  | 'enableAiOfferPlans'
  | 'enableUsersReferralRefresh'
  | 'o10rReleaseSep2024'
  | 'googlePayBraintreeTest'
  | 'indianBankRestrictions'
  | 'iosEuBetaTestEnabled'
  | 'mcLicensePromo'
  | 'newEduFlow'
  | 'pauseSubscriptionTest'
  | 'podcastLandingPage'
  | 'signupMobileAsIos'
  | 'specialOffer'
  | 'membershipPriceIncrease'
  | 'trialOnlyForAnnual'
  ;


/**
 * Config object to request feature flag
 */
type FeatureFlagRequest = {
  /** Feature flag key */
  key: FeatureFlagKeys;
  /** Default value if we can't fetch feature flag from Config Cat service */
  defaultValue: unknown;
  /**
   * Track split result on feature flags module initialization. Set to `false`
   * if you want to track it manually later
   * @default false
   */
  trackSplitResult?: boolean;
};

/**
 * List of all feature flags that will be used in the application
 *
 * @example
 * const featureFlagsConfig: FeatureFlagRequest[] = [
 *   { key: 'oldSuspendedUserFlow', defaultValue: 0 },
 *   { key: 'enableOldSuspendedUserFlow', defaultValue: false, trackSplitResult: true },
 * ];
 */
const featureFlagsConfig: FeatureFlagRequest[] = [
  { key: 'emergencyMessage', defaultValue: 0 },
  { key: 'enableAiOfferPlans', defaultValue: 0 },
  { key: 'enableUsersReferralRefresh', defaultValue: 0 },
  { key: 'o10rReleaseSep2024', defaultValue: 0 },
  { key: 'googlePayBraintreeTest', defaultValue: 0 },
  { key: 'indianBankRestrictions', defaultValue: 0 },
  { key: 'iosEuBetaTestEnabled', defaultValue: 0 },
  { key: 'mcLicensePromo', defaultValue: 0 },
  { key: 'newEduFlow', defaultValue: 0 },
  { key: 'pauseSubscriptionTest', defaultValue: false },
  { key: 'podcastLandingPage', defaultValue: 0 },
  { key: 'signupMobileAsIos', defaultValue: 0 },
  { key: 'specialOffer', defaultValue: 0 },
  { key: 'membershipPriceIncrease', defaultValue: false },
  { key: 'trialOnlyForAnnual', defaultValue: false },
];

const defaultFeatureFlags = featureFlagsConfig.reduce<FeatureFlagsState['featureFlags']>(
  (acc, { key, defaultValue }) => ({ ...acc, [key]: defaultValue }),
  {} as FeatureFlagsState['featureFlags'],
);

export { defaultFeatureFlags };

export default featureFlagsConfig;
