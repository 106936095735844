import React, { useEffect } from 'react';
import {
  FormattedMessage,
  injectIntl,
  defineMessages,
  InjectedIntlProps,
} from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@setapp/ui-kit';

import { RouteComponentProps } from 'react-router-dom';
import urls from 'config/urls';
import externalUrls from 'config/external-urls';

import desktopAppHelper from 'utils/desktop-app-helper';
import logger from 'utils/logger';
import analytics, { events } from 'utils/analytics';

import { detectDesktopApp } from 'state/desktop-app/desktop-app-reducer';
import {
  hasDesktopAppAlreadyDetected as hasDesktopAppAlreadyDetectedSelector,
  isDesktopAppAvailable as isDesktopAppAvailableSelector,
} from 'state/root-reducer';

import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';
import SetappDownloadLinkWrapper from 'components/shared/setapp-download-link-wrapper/setapp-download-link-wrapper';

import './subscription-activated.scss';
import welcomeImage from './images/welcome.svg';

type Props = InjectedIntlProps & {
  isTrialUser?: boolean;
  history: RouteComponentProps['history'];
  location?: RouteComponentProps['location'];
};

const messages = defineMessages({
  welcomeImageAlt: {
    id: 'activatePage.success.welcomeImageAlt',
    defaultMessage: 'A young lady friendly waving hello',
  },
});

const SubscriptionActivated = (props: Props) => {
  const { intl, isTrialUser, history, location } = props;

  const dispatch = useDispatch();
  const hasDesktopAppAlreadyDetected = useSelector(hasDesktopAppAlreadyDetectedSelector);
  const isDesktopAppAvailable = useSelector(isDesktopAppAvailableSelector);

  useEffect(() => {
    if (!hasDesktopAppAlreadyDetected) {
      dispatch(detectDesktopApp());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTitle = () => {
    if (isTrialUser) {
      return <FormattedMessage id="activatePage.success.titleForTrial" defaultMessage="All done!" />;
    }

    return <FormattedMessage id="activatePage.success.title" defaultMessage="Welcome on board" />;
  };

  const getDescription = () => {
    if (isTrialUser) {
      return (
        <FormattedMessage
          id="activatePage.success.descriptionForTrial"
          defaultMessage="Your subscription is set up and will start after your trial."
        />
      );
    }

    return (
      <FormattedMessage
        id="activatePage.success.description"
        defaultMessage="Your membership is active. Thanks for joining Setapp!"
      />
    );
  };

  const handleDownloadSetappClick = () => analytics.trackEvent(
    events.SUBSCRIPTION_ACTIVATED_DOWNLOAD_START,
    { eventLabel: 'Click Button' },
  );

  const handleLaunchSetappClick = () => desktopAppHelper.openDesktopApp()
    .then(() => {
      analytics.trackEvent(events.SUBSCRIPTION_ACTIVATED_LAUNCH_DESKTOP, {
        eventLabel: 'Click Button',
      });
    })
    .catch((error) => {
      logger.logWarn('Couldn\'t launch Setapp desktop app', { error });
    });

  const handleGoToProfile = () => {
    const isEmbeddedPage = location?.pathname?.includes('embed');

    history.push(isEmbeddedPage ? urls.embedSubscription : urls.subscription);
  };

  return (
    <FullscreenLayout>
      <FullscreenLayout.PrimaryContent withLogo>
        <div className="subscription-activated__primary-content" data-qa="subscriptionActivatedContent">
          <div className="h3">{ getTitle() }</div>
          <p className="mb-8 text_lg">{ getDescription() }</p>

          {isDesktopAppAvailable ? (
            <Button
              block
              size="lg"
              onClick={handleLaunchSetappClick}
            >
              <FormattedMessage id="activatePage.success.launchSetappButtonTitle" defaultMessage="Launch Setapp" />
            </Button>
          ) : (
            <SetappDownloadLinkWrapper>
              <Button block size="lg" href={externalUrls.downloadDesktopClient} onClick={handleDownloadSetappClick}>
                <FormattedMessage
                  id="activatePage.success.downloadSetappButtonTitle"
                  defaultMessage="Download Setapp"
                />
              </Button>
            </SetappDownloadLinkWrapper>
          )}

          <Button
            block
            size="lg"
            variant="secondary-outline"
            onClick={handleGoToProfile}
            className="mt-3"
            data-qa="backToProfileButton"
          >
            <FormattedMessage id="activatePage.success.openProfileButtonTitle" defaultMessage="Go to profile" />
          </Button>
        </div>
      </FullscreenLayout.PrimaryContent>
      <FullscreenLayout.SecondaryContent>
        <div className="subscription-activated__secondary-content">
          <img
            src={welcomeImage}
            className="img-responsive"
            alt={intl.formatMessage(messages.welcomeImageAlt)}
            width={573}
            height={520}
          />
        </div>
      </FullscreenLayout.SecondaryContent>
    </FullscreenLayout>
  );
};

export default injectIntl(SubscriptionActivated);
