import React, { type ReactNode } from 'react';

import AppLayoutLoading from 'components/layout/app-layout/app-layout-loading';
import ModalRoot from 'components/layout/app-layout/modal-root/modal-root';
import NotificationsContainer from 'components/shared/notification/notifications-container';
import useAccessTokenGuard from 'components/layout/embed-layout/hooks/use-access-token-guard';
import useLocaleSync from 'components/layout/embed-layout/hooks/use-locale-sync';

import './embed-page.scss';

type Props = {
  children: ReactNode;
};

const EmbedPage = ({ children }: Props) => {
  const isAccessTokenSet = useAccessTokenGuard();
  useLocaleSync();

  if (!isAccessTokenSet) {
    return <AppLayoutLoading />;
  }

  return (
    <div className="embed-page">
      <div className="embed-page__notifications">
        <NotificationsContainer />
      </div>
      <main>
        {children}
      </main>
      <ModalRoot />
    </div>
  );
};

export default EmbedPage;
