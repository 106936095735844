export const DEFAULT_LOCALE = 'en-US';

export const SUPPORTED_LOCALES = [
  'en-US',
  'de-DE',
  'fr-FR',
  'pt-BR',
  'es-ES',
  'it-IT',
  'uk-UA',
  'zh-CN',
];

export const LOCALE_NAMES = {
  'en-US': 'English',
  'fr-FR': 'Français',
  'de-DE': 'Deutsch',
  'pt-BR': 'Português Brasileiro',
  'es-ES': 'Español',
  'it-IT': 'Italiano',
  'uk-UA': 'Українська',
  'zh-CN': '中文',
};

export const SUPPORT_PAGES_LOCALE_ALIASES = {
  'en-US': 'en-us',
  'fr-FR': 'fr-fr',
  'de-DE': 'de',
};

export const LOCALE_ALIASES = {
  en: 'en-US',
  de: 'de-DE',
  fr: 'fr-FR',
  pt: 'pt-BR',
  es: 'es-ES',
  it: 'it-IT',
  uk: 'uk-UA',
  zh: 'zh-CN',
};

export const NEXT_INTL_LOCALE_CODE_MAP = {
  'en': LOCALE_ALIASES.en,
  'fr': LOCALE_ALIASES.fr,
  'de': LOCALE_ALIASES.de,
  'es': LOCALE_ALIASES.es,
  'it': LOCALE_ALIASES.it,
  'pt-br': LOCALE_ALIASES.pt,
  'uk': LOCALE_ALIASES.uk,
};
