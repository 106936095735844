import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useWizardListener } from '@mwkt/react-magic-frame';

import * as authConfig from 'config/auth';
import { COOKIES_SETTINGS, SKIP_ACCESS_TOKEN_GUARD_COOKIE } from '../constants/cookies.constants';
import { FrameWizardAction } from '../constants/frame-wizard.constants';

const useAccessTokenGuard = () => {
  const [isAccessTokenSet, setIsAccessTokenSet] = useState(Cookies.get(SKIP_ACCESS_TOKEN_GUARD_COOKIE) === 'true');

  useEffect(() => {
    Cookies.remove(SKIP_ACCESS_TOKEN_GUARD_COOKIE, COOKIES_SETTINGS);
  }, []);

  useWizardListener(FrameWizardAction.SetAccessToken, (data) => {
    Cookies.set(authConfig.ACCESS_TOKEN_COOKIE, data.accessToken, COOKIES_SETTINGS);
    Cookies.set(
      authConfig.REFRESH_TOKEN_COOKIE,
      data.refreshToken,
      { ...COOKIES_SETTINGS, expires: authConfig.REMEMBER_ME_PERIOD },
    );

    setIsAccessTokenSet(true);
  });

  return isAccessTokenSet;
};

export default useAccessTokenGuard;
