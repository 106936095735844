const urls = {
  root: '/',

  // Unauthorized pages
  login: '/login',
  signup: '/signup',
  signupFamily: '/signup/family',
  signupAddPaymentMethod: '/signup/payment-details',
  signupConfirmEmail: '/signup/confirm-email',
  signupReferral: '/signup/referral',
  redeem: '/redeem',
  successfulAccountDelete: '/successful-account-delete',
  recoverPassword: '/recover-password',
  newPassword: '/new-password',
  socialAuth: '/auth',
  oauthLogin: '/oauth/login',
  authDesktop: '/auth-desktop',
  familyInviteError: '/family-invite-error',
  restrictSetappMobile: '/setapp-mobile-restrict',

  // Authorized pages
  account: '/account',
  devices: '/devices',
  subscription: '/subscription',
  cancelSubscription: '/unsubscribe',
  successfulCancellation: '/successful-cancellation',
  referral: '/referral',
  paymentDetails: '/payment-details',
  paymentHistory: '/payment-history',
  paymentRetry: '/payment-retry',
  giftCards: '/gift-cards',
  family: '/family',
  activateFamilySubscription: '/activate-family',
  activateSubscription: '/activate',
  changePricePlan: '/change-plan',
  successfulRegistration: '/successful-registration',
  successfulRegistrationSingleApp: '/successful-registration-single-app',
  successfulTrialActivation: '/successful-trial-activation',
  successfulAiOfferActivation: '/successful-ai-activation',
  socialAuthWelcome: '/auth/welcome',
  addPaymentMethod: '/add-payment-method',
  discovery: '/discovery',
  deleteAccount: '/delete-account',
  oauthAuthorize: '/oauth/authorize',
  customerOauth: '/customer-oauth',
  appAccessIssue: '/app-access-issue',
  appAccessGranted: '/app-access-granted',
  oauthConfirmEmail: '/oauth/confirm-email',
  dashboardFromTeams: '/dashboard',
  iosApps: '/ios-apps',
  iosAppActivation: '/ios-apps/activate',
  iosCollection: '/ios-apps/collection',
  acceptFamilyInvite: '/family-invite',
  aiOffer: '/ai-offer',
  aiSpecialOffer: '/ai-special-offer',
  setappMobile: '/setapp-mobile',
  hiddenInstallation: '/hidden-installation',

  // Pages to view in the iframe
  embedSubscription: '/embed/subscription',
  embedIosApps: '/embed/ios-apps',
  embedDevices: '/embed/devices',
  embedFamily: '/embed/family',
  embedGiftCards: '/embed/gift-cards',
  embedPaymentHistory: '/embed/payment-history',
  embedSetappMobile: '/embed/setapp-mobile',
  embedChangePricePlan: '/embed/change-plan',
  embedSignupAddPaymentMethod: '/embed/signup/payment-details',
  embedSuccessfulTrialActivation: '/embed/successful-trial-activation',
  embedActivateSubscription: '/embed/activate',
};

export default urls;
