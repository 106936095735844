import React from 'react';
import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import urls from 'config/external-urls';
import SupportLink from 'components/shared/support-link/support-link';
import FooterSocial from 'components/layout/footer/footer-social/footer-social';

import './footer.scss';

const Footer = () => (
  <footer className="footer">
    <div className="footer__container">
      <div className="footer-content">
        <Row>
          <Col sm={8}>
            <span>{`© ${new Date().getFullYear()} MacPaw Way Ltd., 25 Serifou, Allure Center 11, Office No. 11-12, 2nd Floor, 3046 Zakaki, Limassol, Cyprus`}</span>
            <div className="footer-content__terms">
              <SupportLink className="footer-content__link" />
              <a className="footer-content__link" href={urls.termsOfUse} rel="noopener noreferrer">
                <FormattedMessage id="footer.termsLink" defaultMessage="Terms of Use" />
              </a>
              <a className="footer-content__link" href={urls.privacy} rel="noopener noreferrer">
                <FormattedMessage id="footer.policyLink" defaultMessage="Privacy Policy" />
              </a>
            </div>
          </Col>
          <Col sm={4}>
            <div className="footer__social">
              <FooterSocial />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </footer>
);

export default Footer;
