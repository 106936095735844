import { COOKIES_ROOT_DOMAIN } from 'config/app';
import * as authConfig from 'config/auth';

const IS_PROD_ENV = process.env.ENV_NAME === 'production';

export const COOKIES_SETTINGS = {
  domain: COOKIES_ROOT_DOMAIN,
  secure: IS_PROD_ENV ? authConfig.SECURE_COOKIES : true,
  sameSite: IS_PROD_ENV ? 'Lax' : 'none',
};

export const SKIP_ACCESS_TOKEN_GUARD_COOKIE = 'skip_access_token_guard';
