import React from 'react';
import type { ComponentType, ReactNode } from 'react';
import { Route } from 'react-router-dom';
import type { RouteComponentProps, RouteProps } from 'react-router-dom';
import { withSentryRouting } from '@sentry/react';

// eslint-disable-next-line import/no-named-as-default
import AuthenticatedSection from 'components/shared/access-control/authenticated-section';
import CrossAuthGuard from 'components/shared/access-control/cross-auth-guard';
import EmbedLayout from 'components/layout/embed-layout/embed-layout';

type LayoutProps = RouteComponentProps & {
  children: ReactNode;
};

type Props = RouteProps & {
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
  layout?: ComponentType<LayoutProps>;
  authenticated: boolean;
  isEmbed?: boolean;
};

const SentryRoute = withSentryRouting(Route);

const AppRoute = ({
  component: Component,
  layout: Layout,
  isEmbed,
  authenticated,
  ...routeProps
}: Props) => (
  <SentryRoute
    {...routeProps}
    render={(props) => {
      let children;

      if (Layout != null) {
        children = (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        );
      } else {
        children = <Component {...props} />;
      }

      if (isEmbed && authenticated) {
        return (
          <EmbedLayout>
            <CrossAuthGuard>
              <AuthenticatedSection {...props}>
                {children}
              </AuthenticatedSection>
            </CrossAuthGuard>
          </EmbedLayout>);
      }

      if (authenticated) {
        return (
          <CrossAuthGuard>
            <AuthenticatedSection {...props}>
              {children}
            </AuthenticatedSection>
          </CrossAuthGuard>
        );
      }

      return children;
    }}
  />
);

AppRoute.defaultProps = {
  authenticated: false,
  layout: undefined,
};

export default AppRoute;
